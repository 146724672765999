.search-bar {
    display: block;
    margin: 10px auto 20px;
    padding: 10px;
    width: 80%;
    max-width: 400px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    background-color: #f8f8f8;
}

.search-bar:focus {
    border-color: #f8f8f8;
    outline: none;
    box-shadow: 0px 0px 10px rgba(212, 212, 209, 0.607);
}

.pagination-arrow {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #333;
}

.pagination-arrow:hover {
    color: #3333339e;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-button {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
    color: #333;
}

.pagination-button.active {
    background-color: #d9d9d9;
    color: #333;
}

.pagination-button:hover {
    background-color: #e0e0e0;
}

.products-container {
    text-align: center;
    padding: 20px;
}

.products-container h2 {
    color: #61605c;
    font-size: 30px;
}

.products-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.btn-ver-mas {
    display: inline-block;
    margin: 10px auto 0;
    background-color: #d9d9d9;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    color: #494946;
    width: 130px;
    height: 40px;
    cursor: pointer;
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
    line-height: 20px;
    transition: background-color 0.3s;
}

.products-grid p,
h3 {
    color: #494946;
    margin: 0;
    margin-bottom: 5px;
}

.products-grid h3 {
    font-size: 21px;
}

.products-grid p {
    font-size: 16px;
}

.product-card {
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    width: 300px;
    height: max-content;
    max-width: 300px;
}

.product-card img {
    max-width: 100%;
    height: 310px;
    object-fit: cover;
}

@media (min-width:600px) {
    .product-card {
        width: calc(50%- 20px);
    }
}

@media (min-width:660px) {
    .content-prod {
        display: flex;
        height: 130px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }


    .product-card h3 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }

}

@media (min-width: 1300px) {
    .products-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .product-card img {
        width: 240px;
        max-width: 100%;
    }

    .products-container h2 {
        font-size: 40px;
    }

    .products-grid h3 {
        font-size: 23px;
    }

    .products-grid p {
        font-size: 16px;
    }

    .product-card {
        width: calc(33% - 110px);
        max-width: none;
    }

    .products-grid .product-card button {
        height: 38px;
        font-size: 16px;
        width: 118px;
    }

    .btn-ver-mas {
        font-size: 16px;
    }

    .btn-ver-mas:hover {
        background-color: #71717046;
        cursor: pointer;
        transition: 0.8s;
        transform: scale(1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
}