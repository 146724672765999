.payments-container {
    display: grid;
    gap: 4px;
    align-items: center;
    justify-items: center;
    background-color: #f8f8f8;
    margin-top: 3rem;
}

.payment-item img {
    width: 120px;
    height: auto;
}

.payment-item {
    height: 200px;
}

#merc-pago {
    width: 250px;
    padding: 0px 16px;
}

.payment-item p {
    padding: 0;
    margin-top: 0;
}

#envios, #pagos {
    width: 250px;
    padding: 0px 16px;
}

.payment-item p {
    font-size: 20px;
    color: #333;
    text-align: center;
}

#text-mer-pago {
    margin-bottom: 2rem;
}

@media (min-width: 950px) {
    .payments-container {
        grid-template-columns: repeat(3, 1fr);
    }

    #text-mer-pago {
        margin-bottom: 2rem;
    }
}


@media (min-width: 1300px) {

    .payment-item p {
        margin-top: 0rem;
    }
}