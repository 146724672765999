.footer-container {
    margin: 0 auto;
    text-align: center;
    background-color: white;
}

.footer-container .text-container h2 {
    color: #3e3e3e;
    font-size: 30px;
    padding: 40px;
}

.footer-container .text-container p {
    color: #6b6b6b;
    font-size: 14px;
    width: 63%;
    text-align: center;
    margin: 0 auto;
}

.footer-container .copy-container {
    color: #4d4d4d;
    font-size: 15px;
    padding: 20px;
}

.redes-container a i {
    color: #3e3e3e;
}

.redes-container a {
    display: inline-block; 
    margin: 0 10px; 
    line-height: 0;
    font-size: 22px;
    transition: all 0.3s ease; 
    margin-top: 1rem;
    padding: 20px;
}

.text-container{
    margin: 0 auto;
    width: 100%;
}


@media (min-width:680px) {

    .footer-container .text-container h2 {
        font-size: 35px;
    }

    .footer-container .text-container p {
        font-size: 16px;
        width: 70%;
    }

    .footer-container .copy-container {
        font-size: 16px;
    }
}

@media (min-width:850px) {
    .footer-container .text-container p {
        width: 60%;
        font-size: 18px;
    }

    .redes-container a {
        font-size: 24px;
    }
}

@media (min-width:920px) {
    .footer-container .text-container h2 {
        font-size: 37px;
    }

    .footer-container .text-container p {
        font-size: 18px;
        width: 40%;
        line-height: normal;
    }

    .redes-container a {
        font-size: 25px;
    }

    .redes-container a:hover {
        transform: scale(1.2); 
        color: #6b6b6b;
    }

}

@media (min-width:1300px) {
    .footer-container .text-container p {
        font-size: 19px;
        width: 40%;
    }

    .footer-container .copy-container {
        font-size: 18px;
    }

    .redes-container a {
        font-size: 29px;
        padding: 20px;
    }

    .text-container{
        margin: 0 auto;
        width: 100%;
    }
}
