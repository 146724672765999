.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.text-container {
    margin-bottom: 20px;
}

.text-container h2 {
    font-size: 3rem;
    color: #696969;
    margin-bottom: 0.4rem;
}

.text-container p {
    font-size: 1.2rem;
    color: #737373;
    width: 80%;
    margin: 0 auto;
}

.btn-section {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #e3e3e3;
    color: #6d6d6d;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.image-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 0.5rem;
}

.image-container img {
    width: 100px;
    height: auto;
}

.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #696969;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.9s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media (min-width: 768px) {
    .section-container {
        flex-direction: row;
        text-align: left;
    }

    .text-container {
        flex: 1;
        margin-right: 50px;
        text-align: center;
    }

    .image-container img {
        width: 130px;
        height: auto;
    }

    .image-container {
        flex: 1;
        justify-content: flex-start;
        margin-top: 2rem;
    }

    .text-container h2 {
        font-size: 40px;
    }

    .text-container p {
        font-size: 1.1rem;
        width: 95%;
        margin-top: 1.1rem;
    }

    .btn-section {
        margin-top: 28px;
        padding: 6px 20px;
    }
}

@media (min-width:1300px) {

    .image-container img {
        width: 200px;
        height: auto;
        margin-top: 1.7rem;
        display: flex;
        justify-content: space-between;
        margin-right: 26px;
    }

    .text-container h2 {
        font-size: 59px;
        margin-top: 3rem;
    }

    .text-container p {
        font-size: 1.8rem;
        margin-top: 1.1rem;
        width: 90%;
    }

    .btn-section {
        height: 40px;
        margin-top: 3rem;
        transition: background-color 0.3s ease;
    }

    .btn-section:hover {
        background-color: #5b5b5b70;
    }
}

@media (min-width:1500px) {
    .text-container h2 {
        font-size: 67px;
        margin-top: 5rem;
    }

    .text-container p {
        font-size: 2.4rem;
    }

    .btn-section {
        padding: 9px 26px;
        height: 50px;
        margin-top: 3rem;
        transition: background-color 0.3s ease;
        font-size: 23px;
    }

    .image-container img {
        width: 265px;
        margin-top: 3rem;
    }

    .image-container {
        justify-content: space-evenly;
        display: flex;
    }
}