* {
    padding: 0;
    box-sizing: border-box;
    font-family: "Alata", sans-serif;
    font-weight: 400;
    font-style: normal;
}

#navbar {
    background-color: #edeae1;
    padding: 13px;
    height: 74px;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
}

.navbar-links {
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: fixed;
    list-style: none;
    margin: 53px 0;
    width: 61%;
    height: 100vh;
    z-index: 1000;
    overflow-x: hidden;
    background-color: #edeae1;
    transition: transform 0.2s ease;
    transform: translateX(100%);
    text-transform: capitalize;
}

.navbar-links.active {
    transform: translateX(0);
}

.navbar-links li {
    padding: 15px;
    margin-top: 34px;
}

.navbar-links li a {
    text-decoration: none;
    margin-right: 1rem;
    color: #61605c;
}

.navbar-toggle {
    display: block;
    cursor: pointer;
    padding: 5px;
}

.bar {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #8d8890;
    margin: 5.6px 0;
    margin-right: 9px;
    transition: transform 0.3s ease;
}

.logo img {
    height: 39px;
}

.bar.active:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
}

.bar.active:nth-child(2) {
    opacity: 0;
}

.bar.active:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}

@media (min-width: 600px) {
    .navbar-links li a {
        font-size: 20px;
    }

    .navbar-links li {
        padding: 24px;
        margin-top: 2rem;
    }

    .navbar-container {
        width: 100%;
    }
}

@media (min-width:800px) {
    #navbar {
        padding: 10px 20px;
        height: 65px;
        position: fixed;
        width: 20rem;
    }

    .navbar-links {
        flex-direction: row;
        transform: none;
        justify-content: right;
        height: min-content;
        margin: 0;
        padding: 20px;
        width: 44rem;
    }

    .navbar-links li {
        margin: 0;
        padding: 0;
        margin-right: 46px;
    }

    .navbar-links li:last-child {
        margin-right: 12px;
    }

    .navbar-links li a {
        font-size: 18px;
    }

    .navbar-toggle {
        display: none;
    }

    .navbar-container .logo img {
        transform: scale(1.3);
    }
}

@media (min-width:991px) {

    #navbar {
        height: 66px;
        position: fixed;
        width: 60rem;
    }

    .navbar-container .logo img {
        cursor: pointer;
        margin-left: 1rem;
        margin-top: 0.3rem;
        transform: scale(1.4);
    }

    .navbar-links {
        flex-direction: row;
        transition: none;
        transform: none;
        text-align: center;
    }
    
    .navbar-links li a {
        font-size: 19px;
    }

    .navbar-links {
        width: 55rem;
    }
    
    .navbar-container .navbar-links li a:hover {
        color: #8d8890;
    }
}

@media (min-width:1700px) {

    #navbar {
        height: 100px;
        width: 80rem;
    }

    .navbar-links li {
        margin-right: 90px;
    }

    .navbar-links li a {
        font-size: 27px;
    }

    .navbar-links {
        padding: 29px;
    }

    .logo {
        width: 100px;
    }

    .navbar-container .logo img {
        transform: scale(1.9);
        margin-left: 1.5rem;
        margin-top: 1rem;
    }
}

@media (min-width:2090px) {
    #navbar {
        height: 100px;
    }

    .navbar-links li {
        margin-right: 130px;
    }

    .navbar-links li a {
        font-size: 26px;
    }

    .navbar-links {
        padding: 29px;
    }

    .navbar-container .logo img {
        transform: scale(2.2);
        margin-left: 4.5rem;
    }

}