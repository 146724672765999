.product-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    margin-bottom: 6rem;
}

.product-image {
    width: 220px;
    text-align: center;
}

.product-image img {
    max-width: 100%;
    margin-top: 2rem;
}

.product-info {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.product-info h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #4f4f4f;
}

.product-info .price {
    font-size: 20px;
    color: #686767;
    margin-bottom: 10px;
}

.product-info h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #696969;
}

.product-info .description {
    font-size: 16px;
    line-height: 1.5;
    color: #696969;
    text-align: justify;
}

.product-info .description.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: justify;
}

.product-info .description.expanded {
    display: block;
    text-align: justify;
}

.toggle-button {
    background: none;
    border: none;
    color: #1a1a1a;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.modal-product-detail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-product-detail {
    max-width: 80%;
    max-height: 80%;
}

.modal-product-detail img {
    width: 100%;
    height: 320px;
    cursor: pointer;
}

@media (min-width: 768px) {
    .product-detail-container {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        display: flex;
        margin: 0 auto;
        margin-bottom: 6rem;
    }

    .product-image {
        flex: 1.3;
        padding-right: 25px;
        margin-top: 1rem;
        margin-left: 70px;
        margin-bottom: 3rem;
    }

    .product-image img {
        width: 300px;
        cursor: pointer;
    }

    .product-info {
        flex: 2;
        padding-left: 43px;
        text-align: left;
        margin-top: 2rem;
    }

    .product-info .description {
        display: block;
        -webkit-line-clamp: unset;
        overflow: visible;
        white-space: normal;
        width: 90%;
    }

    .product-info .description.collapsed {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .toggle-button {
        display: block;
    }

    .modal-product-detail img {
        height: 420px;
    }
}

@media (min-width: 800px) {
    .product-info {
        margin-top: 6rem;
    }

    .product-image {
        margin-top: 5rem;
    }

    .product-info .description.collapsed {
        -webkit-line-clamp: 8;
    }

    .modal-product-detail img {
        height: 450px;
        margin-top: -1rem;
    }
}

@media (min-width: 900px) {
    .product-info .description.collapsed {
        -webkit-line-clamp: 10;
    }

    .modal-product-detail img {
        height: 590px;
    }
}

@media (min-width: 1200px) {
    .product-info .description {
        width: 90%;
    }

    .product-info {
        margin-top: 6rem;
    }

    .product-info .description.collapsed {
        -webkit-line-clamp: 11;
    }

    .modal-product-detail img {
        height: 740px;
    }
}

@media (min-width: 1300px) {
    .modal-product-detail img {
        height: 520px;
    }
}